import React from "react"

const SelectableInput = ({ value }) => {
  const handleFocus = ({ target }) => target.select()

  return (
    <input
      type="text"
      className="text-input"
      readOnly
      value={value}
      onClick={handleFocus}
      onFocus={handleFocus}
    />
  )
}

export default SelectableInput
