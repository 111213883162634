import React from "react"
import useCopy from "../../hooks/useCopy"

const CopyButton = ({ context, title }) => {
  const { copy, copied } = useCopy(context)

  return (
    <button className="button" onClick={copy}>
      {copied ? "Copied!" : title}
    </button>
  )
}

export default CopyButton
