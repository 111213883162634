import useQueryParams from "./useQueryParams"

const useInformContext = () => {
  const params = useQueryParams()
  const mode = params.get("mode")
  let id = params.get("id")

  let status
  let context

  if (mode === "auth-create") {
    status = "create"
    context = "/create"
  }

  if (mode === "manage") {
    status = "manage"
    context = `/profile?id=${id}`
  }

  if (mode === "signed") {
    status = "signed"
    context = `/`
  }

  if (mode === "create") {
    status = "created"
    context = `podplease.com/profile?id=${id}`
  }

  if (mode === "edit") {
    status = "edited"
    context = `/profile?id=${id}`
  }

  if (mode === "apply") {
    status = "applied"
    context = "/find"
  }

  if (mode === "exists") {
    status = "hasPod"
    context = `/profile?id=${id}`
  }

  if (mode === "missing") {
    status = "noPod"
    context = `/create`
  }

  if (mode === "delete") {
    status = "deleted"
    context = "/"
  }

  if (mode === "reported") {
    status = "reported"
    context = "/"
  }

  if (mode === "alreadyReported") {
    status = "alreadyReported"
    context = "/"
  }

  if (mode === "alreadyApplied") {
    status = "alreadyApplied"
    context = "/"
  }

  return { id, status, context }
}

export default useInformContext
