import { useState } from "react"

const useCopy = (context, time = 1000) => {
  const [copied, setCopied] = useState(false)

  const copy = () => {
    navigator.clipboard.writeText(context)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, time)
  }

  return { copied, copy }
}

export default useCopy
