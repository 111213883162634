import React, { useEffect, useContext } from "react"
import { Link, navigate } from "gatsby"
import { queryCache } from "react-query"

// Utility
import useInformContext from "../hooks/useInformContext"
import withWindow from "../helpers/withWindow"
import { AuthContext } from "../context/AuthContext"

// Layout
import { InfoCard, Selectable, CopyButton } from "../components/inform"

const Inform = () => {
  const { context, status, id } = useInformContext()
  const { hasPod, isAuth } = useContext(AuthContext)
  if (!status) navigate("/404")

  useEffect(() => {
    queryCache.invalidateQueries("AuthContext - hasPod", {
      refetchInactive: true,
    })
  }, [])

  return (
    <>
      {status === "create" && (
        <InfoCard title="You are connected" heading="Welcome aboard!">
          <p>You are successfully connected to PodPlease!</p>
          <Link to="/create" className="button">
            Create a pod
          </Link>
        </InfoCard>
      )}

      {status === "created" && (
        <InfoCard title="Pod created" heading="You're doing swimmingly!">
          <p>
            Your pod has been published, you will receive instructions in your
            email, keep a lookout!
          </p>
          <Selectable value={context} />
          <CopyButton context={context} title="Copy my pod link to share" />
          <Link
            className="button"
            data-variant="ghost"
            to={`/profile?id=${id}`}
          >
            Go to my pod
          </Link>
        </InfoCard>
      )}

      {status === "signed" && (
        <InfoCard title="You are signed in" heading="You're already signed in">
          <Link to={context} className="button" data-variant="ghost">
            Go home
          </Link>
        </InfoCard>
      )}

      {status === "manage" && (
        <InfoCard title="You are connected" heading="Welcome Back!">
          <p>You are successfully connected to PodPlease!</p>
          <Link className="button" data-variant="ghost" to={context}>
            Go to my pod
          </Link>
        </InfoCard>
      )}

      {status === "edited" && (
        <InfoCard title="Pod edited" heading="You're doing swimmingly!">
          <p>Your pod information has been saved.</p>
          <Link to={context} className="button">
            Go to my pod
          </Link>
        </InfoCard>
      )}

      {status === "applied" && (
        <InfoCard title="Applied to pod" heading="You're doing swimmingly!">
          <p>
            {" "}
            You&apos;ve applied for a pod. You&apos;ll receive an email, text,
            or phone call from the pod owner if your application is approved.{" "}
          </p>
          <Link to={context} className="button">
            Browse more pods
          </Link>
        </InfoCard>
      )}

      {status === "hasPod" && (
        <InfoCard
          title="You already have a pod"
          heading="Whoops! We can't do that!"
        >
          <p>
            You can only create one pod at this time. We&apos;ll let you know
            when this changes!
          </p>
          <Link to={context} className="button">
            Go to my pod
          </Link>
        </InfoCard>
      )}

      {status === "noPod" && (
        <InfoCard
          title="You haven't created a pod"
          heading="Quit dolphining around"
        >
          <p>You haven&apos;t created a pod yet! Create one now.</p>
          <Link to={context} className="button">
            Create a pod
          </Link>
          <button
            className="button"
            data-variant="ghost"
            onClick={() => navigate("/")}
          >
            Go to homepage
          </button>
        </InfoCard>
      )}

      {status === "deleted" && (
        <InfoCard title="Pod deleted" heading="No need to blubber!">
          <p>Your pod was deleted.</p>
          <Link to="/create" className="button">
            Create a new pod
          </Link>
          <Link to="/" className="button" data-variant="ghost">
            Go to homepage
          </Link>
        </InfoCard>
      )}

      {status === "reported" && (
        <InfoCard title="Pod reported" heading="Thanks for the report!">
          <p>You&apos;ve reported the pod</p>
          <p>
            We&apos;ll review your report and will take any appropriate actions!
          </p>
          <Link to="/" className="button" data-variant="ghost">
            Go to homepage
          </Link>
        </InfoCard>
      )}

      {status === "alreadyReported" && (
        <InfoCard
          title="Pod reported"
          heading="Whale, whale, whale, you look familiar"
        >
          <p>You&apos;ve already reported this pod!</p>
          <p>
            Your previous report has been received by us and we&apos;ll review
            your report as soon as possible!
          </p>
          <Link to="/" className="button" data-variant="ghost">
            Go to homepage
          </Link>
        </InfoCard>
      )}

      {status === "alreadyApplied" && (
        <InfoCard
          title="Already applied to this pod"
          heading="Quit whaling around. You're doing it on porpoise."
        >
          <p>
            It seems you&apos;ve already applied for this pod. Be patient and
            keep fishing for the right pod.
          </p>

          {hasPod ? (
            <Link to={`/profile?id=${hasPod.id}`} className="button green">
              Go to my pod
            </Link>
          ) : (
            <Link
              to={isAuth ? "/create" : "/signin?mode=create"}
              className="button green"
            >
              Create a pod
            </Link>
          )}

          <Link to="/find" className="button" data-variant="ghost">
            Back to Find a pod
          </Link>
        </InfoCard>
      )}
    </>
  )
}

export default withWindow(Inform)
