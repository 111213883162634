import React from "react"
import { Helmet } from "react-helmet"

const InformationCard = ({ title, heading, children }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <div className="[ flow flow-space-300 shadow-box sign-in ]">
      <h1 className="[ weight-normal text-500 md:text-600 ]">{heading}</h1>
      {children}
    </div>
  </>
)

export default InformationCard
